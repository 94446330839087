import React, { useState } from "react"
import BrowserFrame from "react-browser-frame"

import Layout from "../components/layout"
import Seo from "../components/seo"
import LetsTalk from '../components/LetsTalk'
import { DEMO_URL } from "../constants"

const LOGIN_URL = 'https://clove-demo-login.herokuapp.com/sso/clove-demo-hero/redirect?user=bruce&hub_domain=demo-hero.cloveapp.io&hub_id=15'
const SUCCESS_SPACE_URL = 'https://demo-hero.cloveapp.io/spaces/welcome'
const KB_URL = 'https://demo-hero.cloveapp.io/hc/a/Setting-Up-HeroMode-Integrations-with-Third-Party-Apps'

const SeeItPage = () => {
  const [url, setUrl] = useState(LOGIN_URL)

  return (
    <Layout>
      <Seo title="See It Live | Clove" />

      <div className={"relative bg-white py-16"}>
        <div className={"mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl"}>
          <h2 className="heading-primary">See Clove in Action.</h2>

          <div className="max-w-prose mt-8 space-y-2 text-primary leading-relaxed">
            <p>
              Curious what Clove is all about? We embedded a live hub below so you can see it in action.
              Click around&mdash;check out the success space and integrated knowledge base.
            </p>
          </div>
        </div>
      </div>

      <div className="pb-20 mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="py-4 flex items-center flex-col sm:flex-row gap-4">
          <button className="shadow button-muted w-full sm:w-auto" onClick={() => setUrl(SUCCESS_SPACE_URL)}>Success Space</button>
          <button className="shadow button-muted w-full sm:w-auto" onClick={() => setUrl(KB_URL)}>Knowledge Base</button>
        </div>

        <div className="shadow-xl">
          <BrowserFrame>
            <iframe
              title="Clove demo hub, located at demo-hero.cloveapp.io"
              style={{minHeight: 600, height: '70vh'}}
              className="w-full h-full"
              src={url} />
          </BrowserFrame>
        </div>
      </div>

      <LetsTalk />
    </Layout>
  )
}

export default SeeItPage
